const getClosest = (elem, selector) => {
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;
};

const closeModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const modal = getClosest(e.target, '.modal');

    if (modal) {
        modal.classList.add('closing');

        const backdrop = modal.previousElementSibling && modal.previousElementSibling.matches('.backdrop') ? modal.previousElementSibling : null;
        if (backdrop) backdrop.classList.add('closing');

        setTimeout(function () {
            modal.remove();
            if (backdrop) backdrop.remove();
        }, 600)

    }
}

const modalCloseButton = document.querySelector('.modal__close');
const modalPaymentSuccessButton = document.querySelector('#modal-payment-success-button');

if (modalCloseButton) modalCloseButton.addEventListener('click', closeModal);
if (modalPaymentSuccessButton) modalPaymentSuccessButton.addEventListener('click', closeModal);
